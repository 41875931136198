var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"data-aos":"fade-right"}},[_c('v-breadcrumbs',{staticClass:"breadcrumbsmain",attrs:{"dark":_vm.$store.state.theme == 'dark',"items":[{
            text: 'Activities',
            disabled: true,
            href: 'breadcrumbs_dashboard',
          },{
            text: 'Distribusi',
            disabled: true,
            href: 'Distribusi',
          },{
            text: 'Seedling Change Request',
            disabled: true,
            href: 'breadcrumbs_link_1',
          }],"divider":">","large":""}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }